import { useEffect, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogTitle } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetSelectedStudioBooking } from 'redux/booking'
import { studio } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './SelectStudio.module.scss'

const SelectStudioBooking = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const studios = useAppSelector((state) => state.booking.studiosBooking)
  const selectedStudio = useAppSelector((state) => state.booking.selectedStudioBooking)
  const isMoreThanOneStudio = useAppSelector((state) => state.booking.isMoreThanOneStudioBooking)
  const referralCode = useAppSelector((state) => state.booking.referralCodeBooking)

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'select-studio',
      page_location: `/booking/${id}/select-studio`,
    })
  }, [])

  console.log(studios, 'this is the list of studios')

  return (
    <>
      <div className={styles.container}>
        {/* <Header
          headerText="Select Location"
          showBackButton={referralCode === null}
          onBack={() => {
            navigate(`/booking/${id}/choose-plan`)
          }}
        ></Header> */}
        <div className={styles.header}>
          <div className={styles.headerText}>
            <span>{'Select the location that'}</span>
            <span>{`works best for you.`}</span>
          </div>
          <img src={'/logo2.png'} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.locationWrapper}>
          {studios &&
            studios.map((s: studio) => {
              return (
                <div
                  className={styles.locationContainer}
                  style={{
                    background: selectedStudio && selectedStudio.id === s.id ? '#007f7c' : 'transparent',
                    border: selectedStudio && selectedStudio.id === s.id ? '2px solid #007f7c' : '2px solid #e5e7eb',
                  }}
                  key={s.id}
                  onClick={() => {
                    dispatch(SetSelectedStudioBooking(s))
                  }}
                >
                  <div className={styles.locationDetails}>
                    <div className={styles.locationTextContainer}>
                      <div className={styles.locationTextWrapper}>
                        <div className={styles.locationName}>{s.name}</div>
                        <div>
                          <div className={styles.locationText}>{s.address}</div>
                          <div className={styles.cityAndState}>
                            <div style={{ marginRight: '4px' }}>{s.city.name + ','}</div>
                            <div style={{ marginRight: '4px', textTransform: 'uppercase' }}>{s.state}</div>
                            <div>{s.zipCode}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      style={{
                        // width: '126px',
                        // height: '95.9px',
                        cursor: 'pointer',
                        objectFit: 'cover',
                      }}
                      className={styles.mapImageItem}
                      src={s?.mapImage ? s.mapImage : ''}
                      alt="map"
                      onClick={() => {
                        if (s?.mapLocation) {
                          window.open(s?.mapLocation, '_blank')
                        }
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>

        <Footer
          buttonText={'Continue'}
          isLoading={false}
          // trackerIndex={referralCode !== null ? 1 : 2}
          trackerIndex={1}
          shouldShowFour={isMoreThanOneStudio}
          shouldShowFive={referralCode === null && isMoreThanOneStudio ? true : false}
          isDisabled={!selectedStudio}
          buttonType={'button'}
          onButtonClick={() => {
            if (selectedStudio) {
              navigate(`/booking/${id}/physical-visit`)
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default SelectStudioBooking
